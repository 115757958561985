import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '../../Components/TextField';
import { resetPassword } from '../../api';
import queryString from 'query-string';
import logo from '../../images/logo.png';

const ResetPassword = ({ location }) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState();

  const onClickSubmit = (e) => {
    e.preventDefault();
    if (!password1 || !password2 || password1 !== password2) {
      setError('Passwords must match');
      return;
    }
    const { token, id: userId } = queryString.parse(location.search);
    if (!token || !userId) {
      setError('There was a problem with your request. Please try again.');
      return;
    }
    setError(null);
    resetPassword(userId, token, password1)
      .then((result) => {
        if (result.id) {
          setError('Password reset successful');
        }
      })
      .catch((error) => {
        setError(error.message || 'There was a problem with your request. Please try again.');
      });
  };

  return (
    <div className="login_container">
      <form onSubmit={onClickSubmit} className="login_container__form">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{
            alignSelf: 'center',
          }}
        />
        <p>Reset your password</p>
        <p className="login_container__header">{'Enter your new password'}</p>
        <TextField
          name={'password1'}
          type={'password'}
          placeholder={'password'}
          onChange={(event) => setPassword1(event.target.value)}
          value={password1}
        />
        <p className="login_container__header">{'Confirm your new password'}</p>
        <TextField
          name={'password2'}
          type={'password'}
          placeholder={'password'}
          onChange={(event) => setPassword2(event.target.value)}
          value={password2}
        />
        <button type="submit" className="login_container__submit">
          Submit
        </button>
        <a className="login_container__forgot-password" href="/login">
          Login
        </a>
      </form>
      {error && <p className="login_container__error">{error.toString()}</p>}
    </div>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.object,
};

export default ResetPassword;
